.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rounded-checkbox-container {
	border: 1px solid #bdbdbd;
	border-radius: 21px;
	padding: 0 10px;
	margin-bottom: 6px;
}
.rounded-checkbox-container > label {
	width: 100%;
}
.rounded-checkbox {
	width: 100%;
	border: 1px solid #bdbdbd;
	border-radius: 21px;
	padding: 0 10px;
	margin-bottom: 6px;	
}
.fixed-floating-button {
    top: auto;
    right: 24px;
    bottom: 24px;
    left: auto;
    position: fixed;
    z-index: 10;
}